
import Accordion from './Accordion';
import CustomCarousel from './CustomCarousel'; 
import { Helmet } from 'react-helmet';
import './smallkey.css';
import FareCard from './FareCard';
import GoogleAnalytics from './GoogleAnalytics';
import Rating from '../components/Rating';

function TempoTravellerOnRent(){

  const cardData = 
    {
      keyword: 'Tempo Traveller On Rent in Pune',
      heading: 'Amruta Travels: Tempo Traveller on Rent in Pune',
      headingDescription: 'Planning a group trip or family outing in Pune? Look no further than Amruta Travels for your Tempo Traveller rental needs. Our vehicles are equipped with a range of top-notch features designed to provide unparalleled comfort and convenience. Discover the top features that make our Tempo Travellers the best choice for your travel requirements in Pune.',
      topPlaces: [
        {
          "title": "1. Spacious Interior for Group Comfort",
          "description": "Experience a hassle-free journey with our mini buses that prioritize passenger comfort. The spacious interiors feature ergonomic seating and generous legroom, ideal for group travel, be it a corporate trip or a family outing. Enjoy a relaxing travel experience that’s perfectly tailored for long journeys or short excursions around Pune."
        },
        {
          "title": "2. Advanced Climate Control for Every Season",
          "description": "Beat the fluctuating Pune weather with our mini buses' state-of-the-art air-conditioning systems. Designed to maintain a pleasant temperature, these systems ensure a cool and comfortable ride regardless of the season, making your travel experience truly enjoyable."
        },
        {
          "title": "3. Premium Entertainment Features",
          "description": "Stay entertained on the go with our modern entertainment systems. From LED screens to high-quality audio systems and Bluetooth connectivity, our mini buses transform travel time into leisure time. Perfect for both personal and professional trips, ensuring every journey is engaging and enjoyable."
        },
        {
          "title": "4. Generous Luggage Storage",
          "description": "Travel stress-free with ample luggage space in our mini buses. Whether it’s personal bags or larger suitcases, our vehicles are designed to accommodate all your belongings securely. Enjoy the convenience of having all your essentials well-organized throughout the trip."
        },
        {
          "title": "5. Robust Safety Measures",
          "description": "Your safety is our priority. Each mini bus is equipped with advanced safety features, including individual seat belts, emergency exits, fire extinguishers, and first-aid kits. Travel with peace of mind knowing that you’re in a secure and well-equipped vehicle."
        },
        {
          "title": "6. Skilled and Reliable Drivers",
          "description": "Our professional drivers bring extensive experience and expertise to your travel experience. Familiar with Pune’s routes and traffic, they ensure timely arrivals and smooth navigation. Their courteous service adds a layer of comfort and reliability to every trip."
        },
        {
          "title": "7. Impeccably Maintained Vehicles",
          "description": "Travel in vehicles that reflect our commitment to quality. Our mini buses undergo regular maintenance and thorough cleanliness checks, ensuring you enjoy a hygienic and top-notch travel environment. With well-kept interiors and exteriors, we guarantee a ride that’s as comfortable as it is reliable."
        },
        {
          "title": "8. Customized Rental Options for Your Needs",
          "description": "Every trip is unique, and so are our rental packages. Whether you need a mini bus for a short city tour, a day-long excursion, or an extended journey, our flexible rental options cater to your specific requirements. Tailor your booking to suit your schedule effortlessly."
        },
        {
          "title": "9. Transparent Pricing with Competitive Rates",
          "description": "Enjoy premium services at affordable rates. Our transparent pricing model eliminates hidden charges, ensuring you get excellent value for money. Budget confidently for your journey with detailed cost breakdowns upfront."
        },
        {
          "title": "10. Dedicated Customer Support",
          "description": "Our commitment to service extends beyond the road. From the initial booking to post-trip feedback, our customer service team is available to assist you at every step. Expect prompt responses, professional solutions, and a seamless travel experience."
        }
      ],
      "services": [
        {
          "name": "Mini Bus on Rent in Hadapsar, Pune",
          "description": "Looking for a reliable mini bus on rent in Hadapsar, Pune? Amruta Travels offers spacious and comfortable mini buses equipped with modern amenities, ideal for local events, family gatherings, and corporate functions. Enjoy hassle-free transportation with our well-maintained vehicles."
        },
        {
          "name": "Tempo Traveller On Rent in Pune for Family",
          "description": "Amruta Travels offers spacious and comfortable Tempo Traveller On Rent in Pune for family trips. Ideal for outings and reunions, our vehicles are well-maintained and equipped to provide a smooth and enjoyable travel experience for your loved ones."
        },
        {
          "name": "Mini AC Bus on Rent in Wanowrie, Pune",
          "description": "Stay cool while traveling in Wanowrie with our air-conditioned mini buses. Amruta Travels provides top-notch mini AC bus rental services, ensuring a comfortable and refreshing travel experience, perfect for any occasion or weather."
        },
        {
          "name": "Top Mini Bus on Rent in Kothrud, Pune",
          "description": "Amruta Travels brings you the best mini buses in Kothrud, Pune, for family outings and corporate events. Enjoy spacious seating, advanced amenities, and reliable service for a seamless travel experience. Book now for comfort and convenience!"
        },
        {
          "name": "Mini Bus on Rent in Kharadi, Pune",
          "description": "Rent a mini bus in Kharadi, Pune, for business trips or leisure activities. Amruta Travels ensures a smooth and comfortable ride with our well-equipped and professionally maintained fleet, tailored for all travel needs."
        },
        {
          "name": "Mini Bus on Rent in Kalyani Nagar, Pune",
          "description": "Choose Amruta Travels for mini bus rental services in Kalyani Nagar, Pune. Perfect for corporate gatherings, social events, or family outings, our mini buses offer reliable, comfortable, and high-quality transportation solutions."
        },
        {
          "name": "Mini Bus on Rent in Shivaji Nagar, Pune",
          "description": "For reliable and versatile transportation in Shivaji Nagar, Pune, trust Amruta Travels. Our mini buses cater to family events, group outings, and more, ensuring a comfortable and stress-free journey for all occasions."
        },
        {
          "name": "Best Mini Bus on Rent in Baner, Pune",
          "description": "Amruta Travels provides premium mini bus rental services in Baner, Pune. Equipped with modern amenities and spacious seating, our mini buses guarantee top-notch comfort and convenience for every journey."
        },
        {
          "name": "Mini Bus on Rent for Corporate Events in Pune",
          "description": "Streamline transportation for your corporate events with Amruta Travels. Our mini buses are designed to offer professional and efficient travel solutions, complete with comfortable seating and essential amenities for a productive journey."
        },
        {
          "name": "Mini Bus Hire for Wedding Party in Pimpri Chinchwad",
          "description": "Celebrate your special day in style with Amruta Travels' mini buses in Pimpri Chinchwad. Perfect for transporting wedding guests, our vehicles combine comfort and elegance to ensure a memorable and enjoyable experience."
        },
        {
          "name": "Mini Bus on Rent in Pune for Outstations",
          "description": "Travel comfortably to outstation destinations with Amruta Travels' mini buses. Designed for long journeys, our vehicles come equipped with spacious seating, modern amenities, and professional drivers to ensure a pleasant experience."
        },
        {
          "name": "Pune to Shirdi Mini Bus on Rent",
          "description": "Plan your spiritual journey from Pune to Shirdi with Amruta Travels' mini bus rentals. Enjoy a serene and smooth ride with well-maintained vehicles tailored to make your pilgrimage comfortable and stress-free."
        },
        {
          "name": "Mini Bus on Rent in Pune for Family with Driver",
          "description": "Enjoy stress-free family travel with Amruta Travels' mini bus rentals. Our professional drivers ensure a hassle-free experience, allowing you to relax and focus on spending quality time with your loved ones."
        },
        {
          "name": "Tempo Traveller On Rent in Pune Contact Number",
          "description": "Looking to rent a Tempo Traveller in Pune? Contact Amruta Travels at +91 8421333435 for efficient and reliable service. We ensure smooth, comfortable rides tailored to meet your travel needs."
        }
      ],
      "tableData": [
        ['-Tempo Traveller from Pune to Mahabaleshwar', '-Pune to Mahabaleshwar Tempo Traveller Hire'],
        ['-Pune to Panchgani Tempo Traveller', '-Tempo Traveller for Rent in Pune'],
        ['-17-Seater Tempo Traveller for Mahabaleshwar Trip', '-17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['-Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '-Pune to Tapola Tempo Traveller on Rent'],
        ['-14-Seater Tempo Traveller for Maharashtra Trip', '-Tempo Traveller on Rent for Mahabaleshwar Outings'],
        ['-Mini Bus on Rent for Mahabaleshwar Trip', '-Tempo Traveller on Rent in Pune Rate Per Km']
      ],
      "whychoose": [
        {
          "WhyChooseheading": "Why Choose Amruta Travels for Pune to Mahabaleshwar Tempo Traveller?",
          "WhyChoosedescription": "When it comes to traveling from Pune to Mahabaleshwar, Amruta Travels is your go-to provider for a comfortable and reliable journey. Whether you're heading for a leisure trip or an important event, our Tempo Traveller service ensures that you travel in style and comfort. Here's why Amruta Travels is the best choice for your trip:"
        },
        {
          "WhyChooseheading": "Dependable Service:",
          "WhyChoosedescription": "We pride ourselves on providing reliable and punctual service for your journey from Pune to Mahabaleshwar. You can count on us to adhere to your schedule and ensure a smooth travel experience."
        },
        {
          "WhyChooseheading": "Comfortable Rides:",
          "WhyChoosedescription": "Our well-maintained Tempo Travellers offer a smooth and enjoyable travel experience. With ample space and modern amenities, you can relax and enjoy the journey, whether it's a short or long trip."
        },
        {
          "WhyChooseheading": "Professional Drivers:",
          "WhyChoosedescription": "Our experienced drivers are dedicated to your safety and comfort. They are well-versed with the routes from Pune to Mahabaleshwar, ensuring a secure and pleasant journey."
        },
        {
          "WhyChooseheading": "Competitive Pricing:",
          "WhyChoosedescription": "We offer transparent and competitive rates with no hidden charges. Our pricing structure ensures that you get the best value for your money, making it easy to plan your travel expenses."
        },
        {
          "WhyChooseheading": "Flexible Booking:",
          "WhyChoosedescription": "With easy and flexible booking options, you can tailor your rental to fit your schedule and needs. Whether it's a day trip or a longer journey, we accommodate your requirements seamlessly."
        },
        {
          "WhyChooseheading": "24/7 Customer Support:",
          "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with any inquiries or travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
        },
        {
          "WhyChooseheading": "Clean and Safe Vehicles:",
          "WhyChoosedescription": "We ensure that our vehicles are regularly cleaned and maintained to the highest standards. Hygiene and safety are our priorities, so you can travel with peace of mind."
        }
      ]

    };

    
   
    const faqData = [
      {
        question: "How can I book a Tempo Traveller from Pune to Mahabaleshwar?",
        answer: "You can book a Tempo Traveller by calling us at +91 8421333435 or through our website. Our team will assist you with the booking process."
      },
      {
        question: "What types of Tempo Travellers are available for the Pune to Mahabaleshwar journey?",
        answer: "We offer a range of Tempo Travellers, including 17-seaters and mini buses, to accommodate different group sizes and preferences."
      },
      {
        question: "Are there any additional charges for the Tempo Traveller service?",
        answer: "Our pricing is transparent, with no hidden fees. Any additional charges for extra stops or extended mileage will be communicated in advance."
      },
      {
        question: "How do I know if the Tempo Traveller is well-maintained?",
        answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition for your safety and comfort."
      },
      {
        question: "Can I choose the driver for my trip?",
        answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences when booking."
      },
      {
        question: "What if I need to change my booking?",
        answer: "Contact our customer support team to discuss any changes. We will accommodate your requests based on availability."
      },
      {
        question: "Is there a minimum rental period for the Tempo Traveller service?",
        answer: "We offer flexible rental periods to fit your needs, whether for a day trip or a longer journey."
      },
      {
        question: "What amenities are included in the Tempo Traveller service?",
        answer: "Our vehicles come with modern amenities such as air conditioning, comfortable seating, and entertainment options."
      },
      {
        question: "What is the cancellation policy for the Tempo Traveller service?",
        answer: "Cancellation policies may vary. Please contact us for details on our cancellation terms and any applibusle fees."
      },
      {
        question: "How can I contact Amruta Travels for support during my journey?",
        answer: "Reach our 24/7 customer support team at +91 8421333435 for any assistance during your trip."
      }
    ];
    

    const testimonials = [
      {
        name: "Miss Rasika Sharma",
        text: "I recently rented a Tempo Traveller from Amruta Travels for our trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, spacious, and well-maintained. The driver was professional and made our journey enjoyable. Highly recommend Amruta Travels for group trips!"
      },
      {
        name: "Mr. sunil Patel",
        text: "Our corporate team used Amruta Travels for a Mahabaleshwar outing, and we were thoroughly impressed. The Tempo Traveller was luxurious and comfortable, perfect for our needs. The booking process was smooth, and the driver was excellent. We’ll definitely choose Amruta Travels again for future trips."
      }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: " Tempo Traveller On Rent In Pune Contact Number",
        contactNumbers: [
          "+91 8421333435",
          "+91 7875774499",
          "+91 7620411277",
          "+91 9284718314"
        ],
        email: "booking@amrutatravels.com"
      };


      const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];

          const jsonLdData = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Amruta Travels",
            "description": "Hire Tempo Traveller On Rent in Pune for local and outstation trips. Comfortable, affordable, and spacious options for group travel. Book online now!",
            "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
            "logo": "https://amrutatravel.com/images/logo.png",
            "image": "https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg",
            "telephone": "+91 8421333435",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91 8421333435",
              "contactType": "Customer Service",
              "areaServed": "IN",
              "availableLanguage": ["English", "Hindi", "Marathi"]
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Address of the rental service",
              "addressLocality": "Pune",
              "addressRegion": "Maharashtra",
              "postalCode": "411001",
              "addressCountry": "IN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "18.5204",
              "longitude": "73.8567"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.7",
              "reviewCount": "180"
            },
            "sameAs": [
              "https://www.facebook.com/amrutatravels",
              "https://www.instagram.com/amruta_travels"
            ],
            "keywords": [
              "Tempo Traveller On Rent in Pune",
              "Affordable Tempo Traveller Pune",
              "Luxury Tempo Traveller Rental Pune",
              "Outstation Tempo Traveller Pune",
              "Pune to Shirdi Tempo Traveller",
              "Pune to Mahabaleshwar Tempo Traveller",
              "Pune to Goa Tempo Traveller Rental"
            ]
          };
     
    return(
        <div>
          <GoogleAnalytics/>

          <Helmet>
    <title>Tempo Traveller On Rent in Pune ₹25 Per Km | Affordable & Luxury Options | Amruta Travels</title>
    <meta name="description" content="Hire 17 Tempo Traveller on Rent in Pune for outstation trips and local journeys. Affordable rates, comfortable rides, and luxury options available. Book now!" />
    <meta name="keywords" content="Tempo Traveller On Rent in Pune, Tempo Traveller Rental Pune, 17 Seater Tempo Traveller Pune, Traveller Bus on Rent Pune, Luxury Tempo Traveller Pune, Outstation Tempo Traveller Rental Pune, Pune to Shirdi Tempo Traveller Rate, Pune to Mahabaleshwar Tempo Traveller, Pune to Goa Tempo Traveller Rental" />

    {/* Open Graph Tags for Social Sharing */}
    <meta property="og:title" content="Tempo Traveller On Rent in Pune | Affordable & Luxury Options | Amruta Travels" />
    <meta property="og:description" content="Looking for Tempo Traveller On Rent in Pune? Book affordable and luxury Tempo Travellers for group travel. Perfect for outstation trips and events. Call now!" />
    <meta property="og:url" content="https://amrutatravel.com/tempo-traveller-on-rent-in-pune" />
    <meta property="og:image" content="https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg" />

    {/* Twitter Card Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Tempo Traveller On Rent in Pune | Affordable & Luxury Options | Amruta Travels" />
    <meta name="twitter:description" content="Hire a Tempo Traveller in Pune for your travel needs. Affordable rates and luxury options. Perfect for outstation and local trips. Book now!" />
    <meta name="twitter:image" content="https://amrutatravel.com/images/tempo-traveller-rent-pune.jpg" />

    {/* Structured Data Markup for Local Business */}
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
    />
    <Rating/>
</Helmet>



<section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

<section>
    <div className="container-fluid ">
<div className="row">
<div className="col-12 col-md-7">
    <img src='\img\Keyword Based Image\Keyword Based Image-01.jpg' alt='img'/>
<h1 className="py-1">{cardData.heading} </h1><p>{cardData.headingDescription}</p>
<div>
      {cardData.topPlaces.map((place, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{place.title}</h3>
          <p>{place.description}</p>
        </div>
      ))}
    </div>
    <div>
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 Colo">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

   <div id="why-choose-section">
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   

<div>
      <h3 className='Colo'>FAQs About Pune to Mahabaleshwar Tempo Traveller for Amruta Travels</h3>
      <Accordion data={faqData} />
    </div>

    <CustomCarousel items={carouselItems} options={carouselOptions} />

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='Colo'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='Colo'>Email Id:</strong></p>
        <p>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" >
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row ">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8421333435" className="d-block  whit">+91 8421333435</a>
            <a href="tel:+91 7875774499" className="d-block  whit">+91 7875774499</a>
            <a href="tel:+91 7620411277" className="d-block  whit">+91 7620411277</a>
            <a href="tel:+91 9284718314" className="d-block  whit">+91 9284718314</a>
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded p-3 text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@amrutatravel.com" className="whit d-block">
              booking@amrutatravel.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
              SSKTravels24:<br />
              Office No.- 2, Ground Floor,<br />
              Wings H-(Puram), Prayeja City,<br />
              Phase-2, Near Prayeja City,<br />
              Vadgaon Budruk,<br />
              Pune, Maharashtra - 411041
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<FareCard/>

</div>
    </div>
</section>



        </div>
    );
}
export default TempoTravellerOnRent;