// import Slider from "../components/Slider";
import PricingSection from "./PricingSection";
import TeamSection from "./TeamSection";
import TestimonialSection from "./TestimonialSection";
import BlogSection from "./BlogSection";
import CtaSection from "./CtaSection";
import { Helmet } from 'react-helmet';
import OfferingsSection from "./OfferingsSection";
import SliderSection from "./SliderSection";
import LogoSlider from "./LogoSlider";
import RecentTrip from "./RecentTrip";
import Carousel from "../components/Carousel";
import Rating from '../components/Rating';
function Home() {



  return (
    
    <div>

<Helmet>
  <title> Tempo Traveller On Rent in Pune ₹25 Per Km | Contact: +91 8421333435 | Amruta Travels | Luxury Tempo Traveller Hire for Outstation & Local Trips</title>
  <meta name="description" content="Looking for a luxury Tempo Traveller on rent in Pune for your next trip? Whether you're planning a family outing, a corporate event, or an outstation trip, Amruta Travels offers top-notch luxury Tempo Travellers that combine comfort, style, and affordability. Our fleet of luxury Tempo Travellers is ideal for groups, ensuring that your travel experience is both comfortable and enjoyable." />
  <meta name="keywords" content="Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Tempo Traveller On Rent in Pune",
        "description": "Affordable Tempo Traveller rentals for local and outstation trips in Pune. Choose from 17-seater to luxury Tempo Travellers for trips to Shirdi, Mahabaleshwar, Goa, and more. Book with Amruta Travels for a comfortable journey.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Office No.- 2, Ground Floor, Wings H-(Puram), Prayeja City, Phase-2, Near Prayeja City, Vadgaon Budruk",
          "addressLocality": "Pune",
          "addressRegion": "Maharashtra",
          "postalCode": "411041",
          "addressCountry": "IN"
        },
        "telephone": "+91-8421333435",
        "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
        "logo": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "image": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "priceRange": "₹5000 - ₹25000",
        "offers": {
          "@type": "Offer",
          "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
          "priceCurrency": "INR",
          "price": "7000",
          "priceValidUntil": "2024-12-31"
        },
        "serviceArea": {
          "@type": "Place",
          "name": "Pune",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "18.5204",
            "longitude": "73.8567"
          }
        },
        "availableChannel": {
          "@type": "ServiceChannel",
          "serviceUrl": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune"
        },
        "keywords": "Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune"
      }
    `}
  </script>
 <Rating/>
</Helmet>


    <Carousel/>
      <OfferingsSection />
      <CtaSection />
      <PricingSection />
      <TeamSection />
      <TestimonialSection />
      <BlogSection />
      <div className="py-md-3 my-md-5">
      <LogoSlider/>

      </div>

    </div>

  );
}
export default Home;